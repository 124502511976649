// setup container for the functions
window.reece = window.reece || {};

import "@reece-trs-online/components/dist/postcode-update.js";
import "@reece-trs-online/components/dist/add-to-cart.js";

import "./../_common/main.scss";
import "./../_common/print.scss";
import "./header";
import "./../site-message/site-message";

// other scripts
import "./../scripts/max-lite/index";

// load the search bar web component
let searchbarWebComponent = document.createElement("script");
let headEl = document.getElementsByTagName('head')[0]
searchbarWebComponent.src = "/search/static/search-bar/no-cache/search-bar.js";
headEl.appendChild(searchbarWebComponent);

// load maX analytics script
let analyticsScript = document.createElement("script");
analyticsScript.type = "text/javascript";
analyticsScript.src = "/max/web-analytics/static/js/max-analytics.nocache.js";
headEl.appendChild(analyticsScript);

// load the stencil web component library
const stencilWebComponentRef = {
  aws:    `//cf-${(window?.location?.host.split('.')?.[0] ?? 'www')}-trs-online-components.s3.ap-southeast-2.amazonaws.com/web-components/web-components/web-components.js`,
  local:  "/web-component-library/web-components.js" 
};
let webComponentLibrarySrc = stencilWebComponentRef.local;

let adobeAnalyticsModule = document.createElement("script");
adobeAnalyticsModule.src = webComponentLibrarySrc;
adobeAnalyticsModule.type = "module";
headEl.appendChild(adobeAnalyticsModule);
let adobeAnalyticsNomodule = document.createElement("script");
adobeAnalyticsNomodule.src = webComponentLibrarySrc;
adobeAnalyticsNomodule.noModule = true;
headEl.appendChild(adobeAnalyticsNomodule);

// insert the adobe analytics module (once the <body> tag has loaded)
document.addEventListener("DOMContentLoaded", function() {
  let adobeAnalyticsEl = document.createElement("rg-analytics-xdm");
  let bodyEl = window.document.getElementsByTagName('body')[0];
  bodyEl.insertBefore(adobeAnalyticsEl, bodyEl.firstChild);
}, false);
